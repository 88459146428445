import { SharedModule } from './../shared/shared.module';
import { AuthGuard, NoAuthGuard } from './../core/services/auth-guard.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';

import { routing } from './auth.routing';

import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { VerifyComponent } from './verify/verify.component';

import { environment } from '../../environments/environment';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    routing,
    MatListModule,
    MatIconModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    SharedModule,
    MatSelectModule
  ],
  declarations: [
    SignupComponent,
    LoginComponent,
    VerifyComponent,
  ],
  providers: [
    AuthGuard,
    NoAuthGuard,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.config.RECAPTCHA_SITE_KEY } as RecaptchaSettings,
    },

  ],
  exports: [ReactiveFormsModule, RecaptchaModule]
})
export class AuthModule { }
