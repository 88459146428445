import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { SessionStorageService, SubscriptionLike } from 'src/app/core/services/session-storage.service';

/**
 *
 *
 * @export
 * @class VerifyComponent
 */
@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})

export class VerifyComponent {
  hash: string;
  verifyold: boolean;
  verifyUsed: boolean;
  recaptcha: string = '';
  verifySuccess: boolean;
  enableRecaptch: boolean = true;
  isFormSubmitted: boolean = false;
  missingRecaptcha: boolean = false;
  recaptchaTheme: string = 'light';
  darkTheme: boolean = false;

  private subs: SubscriptionLike;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private sessionStorage: SessionStorageService,
  ) {
    this.hash = '';

    this.route.params.subscribe(params => {
      this.hash = params['hash'];
    });

    this.subs = this.sessionStorage.observe('THEME').subscribe(
      (data) => {
        this.recaptchaTheme = (data === 'dark-theme' ? 'dark' : 'light');
        this.darkTheme = (data === 'dark-theme');
      }
    );

    if (!this.hash || this.hash === '') {
      this.router.navigate(['/login']);
    }
  }

  public resolved(captchaResponse: string) {
    this.recaptcha = captchaResponse;
    this.missingRecaptcha = false;
  }

  validateForm() {
    let valid = true;

    if (this.enableRecaptch && this.recaptcha === '') {
      this.missingRecaptcha = true;
      valid = false;
    }
    return valid;
  }

  verify() {
    if (this.validateForm()) {
      const data = {
        verify: this.hash,
        'g-recaptcha-response': this.recaptcha
      };
      this.auth.verifyEmail(data)
        .subscribe((response: any) => {
          if (response.response === 'success') {
            this.verifyold = false;
            this.verifyUsed = false;
            this.verifySuccess = true;
          } else if (response.response === 'failure' &&
            response.reason === 'VERIFY_LINKEXPIRED') {
            this.verifySuccess = false;
            this.verifyold = true;
            this.verifyUsed = false;
          } else if (response.response === 'failure') {
            this.verifySuccess = false;
            this.verifyold = false;
            this.verifyUsed = true;
          }
          this.isFormSubmitted = true;
        });
    }
  }

}
