<div [ngClass]="darkTheme ? 'dark-theme-background' : 'light-theme-background'" class="container-fluid px-0">
  <div class="blueprint-background"> <!-- start blueprint background -->
    <div class="row mx-0" *ngIf="!registerSuccessStep1">
      <div class="container mt-0 mt-lg-4 mb-2 mb-lg-5 py-2 py-lg-4 mx-auto">
        <div class="row d-flex justify-content-center">
          <div class="d-none d-xl-block col-3">
            <div [ngClass]="darkTheme ? 'dark-signup-box':'signup-box'"
              class="py-4 mb-3 d-flex flex-column justify-content-around">
              <h2 class="text-center mt-3 px-4 orange-text" i18n><b>You are almost there!</b></h2>
              <div class="row d-flex justify-content-center">
                <div class="col-auto d-flex flex-column align-items-center mb-4 py-4">
                  <img class="d-block my-1"
                    src="/assets/chainex/images/pages/auth/signup/light-mode/yellow_1.svg">
                  <img class="d-block my-1"
                    src="/assets/chainex/images/pages/auth/signup/light-mode/arrow.svg">
                  <img class="d-block my-1"
                    src="/assets/chainex/images/pages/auth/signup/light-mode/orange_2.svg">
                  <img class="d-block my-1"
                    src="/assets/chainex/images/pages/auth/signup/light-mode/arrow.svg">
                  <img class="d-block my-1"
                    src="/assets/chainex/images/pages/auth/signup/light-mode/orange_3.svg">
                </div>
                <div class="col-5 d-flex flex-column justify-content-between">
                  <div class="mt-4 d-flex flex-column justify-content-center">
                    <img *ngIf="!darkTheme"
                      src="/assets/chainex/images/pages/home/light-mode/sign_up.svg">
                    <img *ngIf="darkTheme"
                      src="/assets/chainex/images/pages/home/dark-mode/sign_up.svg">
                    <h4>SIGN UP & VERIFY</h4>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <img *ngIf="!darkTheme"
                      src="/assets/chainex/images/pages/home/light-mode/deposit.svg">
                    <img *ngIf="darkTheme"
                      src="/assets/chainex/images/pages/home/dark-mode/deposit.svg">
                    <h4>DEPOSIT</h4>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <img *ngIf="!darkTheme"
                      src="/assets/chainex/images/pages/home/light-mode/trade.svg">
                    <img *ngIf="darkTheme"
                      src="/assets/chainex/images/pages/home/dark-mode/trade.svg">
                    <h4>TRADE</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-9 px-0 px-lg-3">
            <div [ngClass]="darkTheme ? 'dark-signup-box':'signup-box'" class="mx-2 px-2 px-lg-4 py-4">
              <div class="row d-flex align-items-center justify-content-center mx-0 pb-3">
                <img src="/assets/chainex/images/pages/auth/signup/light-mode/signup_icon.svg">
                <h2 class="ml-0 ml-lg-5 text-center" i18n><span class="orange-text"><b>Create your account</b></span></h2>
              </div>
              <form novalidate (ngSubmit)="onSubmit1()" [formGroup]="formData">
                <div class="row d-flex align-items-top justify-content-center mx-0 pb-2 my-3">
                  <div class="pl-0 pl-lg-3 col-auto">
                    <img
                      class="icon-container"
                      src="/assets/chainex/images/pages/auth/signup/light-mode/username.svg"
                    >
                  </div>
                  <div class="col-10 col-lg-5 px-0 px-lg-3">
                    <input [ngClass]="darkTheme ? 'dark-input' : 'light-input'" class="signup-input w-100 pl-2"
                      placeholder="First Name" type="text" formControlName="firstName" name="firstName" id="firstName"
                      required />
                    <ng-container *ngTemplateOutlet="firstNameRequired"></ng-container>
                  </div>
                  <div class="pl-0 pl-lg-3 col-auto d-block d-lg-none">
                    <div class="icon-container"></div>
                  </div>
                  <div class="col-10 col-lg-5 px-0 px-lg-3 mt-4 mt-lg-0">
                    <input [ngClass]="darkTheme ? 'dark-input' : 'light-input'" class="signup-input w-100 pl-2"
                      placeholder="Last Name" type="text" formControlName="lastName" name="lastName" id="lastName"
                      required />
                    <ng-container *ngTemplateOutlet="lastNameRequired"></ng-container>
                  </div>
                </div>
                <div class="row d-flex align-items-top justify-content-center mx-0 pb-2 my-3">
                  <div class="pl-0 pl-lg-3 col-auto">
                    <img
                      class="icon-container"
                      src="/assets/chainex/images/pages/auth/signup/light-mode/email.svg"
                    >
                  </div>
                  <div class="col-10 col-lg-5 px-0 px-lg-3">
                    <input [ngClass]="darkTheme ? 'dark-input' : 'light-input'" class="signup-input w-100 pl-2"
                      placeholder="Email" type="email" formControlName="email" name="email" id="email" required />
                    <ng-container *ngTemplateOutlet="emailRequired"></ng-container>
                    <ng-container *ngTemplateOutlet="emailInvalid"></ng-container>
                  </div>
                  <div class="pl-0 pl-lg-3 col-auto d-block d-lg-none">
                    <div class="icon-container"></div>
                  </div>
                  <div class="col-10 col-lg-5 px-0 px-lg-3 mt-4 mt-lg-0">
                    <input [ngClass]="darkTheme ? 'dark-input' : 'light-input'" class="signup-input w-100 pl-2"
                      placeholder="Referral Code (Optional)" type="text" formControlName="referredBy" name="referredBy"
                      id="referredBy" />
                  </div>
                </div>
                <div class="row d-flex align-items-top justify-content-center mx-0 pb-2 my-3">
                  <div class="pl-0 pl-lg-3 col-auto">
                    <img
                      class="icon-container"
                      src="/assets/chainex/images/pages/auth/signup/light-mode/password.svg"
                    >
                  </div>
                  <div class="col-10 col-lg-5 px-0 px-lg-3">
                    <input #passwordInput class="signup-input w-100 pl-2"
                      [ngClass]="darkTheme ? 'dark-input' : 'light-input'" placeholder="Password" type="password"
                      formControlName="password" name="password" id="password" autocomplete="new-password" required />
                    <small matSuffix class="signup-error password-strength" i18n>Strength:
                      <meter max="12" [value]="passwordStrengthValidator(passwordInput.value)"
                        id="password-strength-meter"></meter>
                    </small>
                    <ng-container *ngTemplateOutlet="passwordRequired"></ng-container>
                    <ng-container *ngTemplateOutlet="passwordTooShort"></ng-container>
                    <ng-container *ngTemplateOutlet="passwordInvalid"></ng-container>
                  </div>
                  <div class="pl-0 pl-lg-3 col-auto d-block d-lg-none">
                    <div class="icon-container"></div>
                  </div>
                  <div class="col-10 col-lg-5 px-0 px-lg-3 mt-4 mt-lg-0">
                    <input [ngClass]="darkTheme ? 'dark-input' : 'light-input'" class="signup-input w-100 pl-2"
                      placeholder="Confirm Password" formControlName="confirmPassword" name="confirmPassword"
                      id="confirmPassword" type="password" autocomplete="new-password" required />
                    <ng-container *ngTemplateOutlet="confirmPasswordRequired"></ng-container>
                    <ng-container *ngTemplateOutlet="confirmPasswordMismatch"></ng-container>
                  </div>
                </div>
                <div class="row d-flex align-items-top justify-content-center mx-0 pb-2 my-3">
                  <div class="pl-0 pl-lg-3 col-auto">
                    <img
                      class="icon-container"
                      src="/assets/chainex/images/pages/auth/signup/light-mode/campaign.svg"
                    >
                  </div>
                  <div class="col-10 px-0 px-lg-3">
                    <div class="col-12 col-lg-6 pl-0">
                      <mat-form-field class="w-100 py-0 my-0">
                        <mat-select class="pb-0 w-100 mat-dropdown-box" placeholder="Where did you hear about us?" [formControl]="campaignTypeSelection">
                          <mat-option *ngFor="let campaign of campaignTypes" [value]="campaign">
                            {{ campaign }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="pl-0 pl-lg-3 col-auto d-block d-lg-none">
                    <div class="icon-container"></div>
                  </div>
                </div>
                <div class="row d-flex align-items-center justify-content-center mx-0 pb-2 my-3"
                  [class.error]="formData.controls['country'].errors && (formData.controls['country'].dirty || formData.controls['country'].touched)">
                    <div class="pl-0 pl-lg-3 col-auto d-block d-lg-none">
                      <div class="icon-container"></div>
                    </div>
                    <div class="col-10 text-lg-center d-flex flex-column justify-content-center px-0 px-lg-3">
                      <mat-checkbox class="mx-auto mt-2" color="primary" formControlName="country" name="country"
                      id="country" required>
                      <span i18n [ngClass]="{'white-text': darkTheme}">
                        I do not reside in a
                        <a href="/terms#geographical_exclusions" target="_blank">
                          <u>geographical area</u>
                        </a>
                        barred from using the ChainEX services
                      </span>
                    </mat-checkbox>
                    <ng-container *ngTemplateOutlet="geographicalAgreement"></ng-container>
                  </div>
                  <div class="pl-0 pl-lg-3 col-auto d-none d-md-block d-lg-none">
                    <div class="icon-container"></div>
                  </div>
                </div>

                <div class="row d-flex align-items-center justify-content-center pb-2 my-3" *ngIf="enableRecaptch">
                  <div class="pl-0 pl-lg-3 col-auto d-none d-sm-block d-lg-none">
                    <div class="icon-container"></div>
                  </div>
                  <div class="col-12 col-sm-10 text-lg-center px-0 px-lg-3" >
                    <div class="recaptcha-overlay"></div>
                    <re-captcha id="recaptcha" theme="{{recaptchaTheme}}" (resolved)="resolved($event)" required>
                    </re-captcha>
                    <ng-container *ngTemplateOutlet="recaptchaRequired"></ng-container>
                  </div>
                  <div class="pl-0 pl-lg-3 col-auto d-none d-md-block d-lg-none">
                    <div class="icon-container"></div>
                  </div>
                </div>
                <div class="row d-flex align-items-center justify-content-center mx-0 pb-2 my-3">
                  <div class="pl-0 pl-lg-3 col-auto d-block d-lg-none">
                    <div class="icon-container"></div>
                  </div>
                  <div class="col-10 text-lg-center px-0 px-lg-3" >
                  <span class="text-left text-md-center w-100">By signing up you agree to our <a href="/terms" target="_blank">Terms of Use</a>
                    and <a href="/privacy" target="_blank">Privacy
                      Policy</a></span>
                  </div>
                  <div class="pl-0 pl-lg-3 col-auto d-none d-md-block d-lg-none">
                    <div class="icon-container"></div>
                  </div>
                </div>
                <div class="row d-flex align-items-center justify-content-center mx-0 pb-2 my-3">
                  <div class="pl-0 pl-lg-3 col-auto d-block d-lg-none">
                    <div class="icon-container"></div>
                  </div>
                  <div class="col-10 px-0 px-lg-3 mx-auto d-flex flex-column align-items-center">
                    <div id="error-summary" class="col-12 text-center px-0" *ngIf="(formData.touched || formData.dirty) && formData.invalid">
                      <div class="d-block w-100 d-lg-none">
                        <ng-container *ngTemplateOutlet="firstNameRequired"></ng-container>
                        <ng-container *ngTemplateOutlet="lastNameRequired"></ng-container>
                        <ng-container *ngTemplateOutlet="emailRequired"></ng-container>
                        <ng-container *ngTemplateOutlet="emailInvalid"></ng-container>
                        <ng-container *ngTemplateOutlet="passwordRequired"></ng-container>
                        <ng-container *ngTemplateOutlet="passwordTooShort"></ng-container>
                        <ng-container *ngTemplateOutlet="passwordInvalid"></ng-container>
                        <ng-container *ngTemplateOutlet="confirmPasswordRequired"></ng-container>
                        <ng-container *ngTemplateOutlet="confirmPasswordMismatch"></ng-container>
                        <ng-container *ngTemplateOutlet="geographicalAgreement"></ng-container>
                        <ng-container *ngTemplateOutlet="recaptchaRequired"></ng-container>
                      </div>
                      <small class="signup-error mat-error d-block w-100 mt-2" *ngIf="failedRegister" i18n>
                        Please review the errors above and retry submission
                      </small>
                    </div>

                    <div class="my-0 mx-auto col-7" *ngIf="failedRegister">
                      <div class="mb-0 alert alert-danger">
                        <p class="mb-0" [innerHTML]="failedMessage|i18n"></p>
                      </div>
                    </div>

                    <button type="submit"
                      class="mat-raised-button mat-primary orange-gradient w-100 mt-2 py-1 main-button" i18n>SIGN
                      UP</button>
                    <span class="text-center">Already have an account? <a routerLink="/login">Sign in here</a></span>
                  </div>
                  <div class="pl-0 pl-lg-3 col-auto d-none d-md-block d-lg-none">
                    <div class="icon-container"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mx-0" *ngIf="registerSuccessStep1">
      <div class="container mt-0 mt-lg-4 mb-2 mb-lg-5 pt-2 pt-lg-4 pb-2 px-0 mx-auto">
        <div class="w-75 px-auto mx-auto">
          <div [ngClass]="darkTheme ? 'dark-signup-box':'signup-box'" class="mx-2 px-2 px-lg-4 py-4">
            <div class="row d-flex align-items-center justify-content-center mx-0">
              <span class="mx-3 mt-2">
                <img src="assets/{{siteName}}/images/icons/check.png" loading="lazy"/>
              </span>
              <span class="title">
                <h2 class="my-0 text-center" i18n>Sign&nbsp;Up Successful</h2>
              </span>
            </div>

            <div class="col-12 heading mt-4 mb-4 text-center">
              <ng-container i18n>An activation email has been sent to:</ng-container>
              <br />
              <span class="text-primary font-weight-bold">{{ registeredEmail }}</span>
            </div>
            <div class="col-12 heading mt-2 mb-4 text-center" i18n>
              Please check your inbox and follow the instructions on the email to activate your account and login.
            </div>
            <div class="col-12 heading mt-2 mb-4 text-center" i18n>
              You may safely close this screen or navigate back to our home page by selecting the button below:
            </div>
            <div class="col-10 col-lg-6 mx-auto heading mt-2 mb-2">
              <a routerLink="/">
                <button mat-raised-button color="primary"
                  class="mat-raised-button mat-primary orange-gradient w-100 mx-auto mt-2 py-1 main-button" i18n>RETURN
                  HOME</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> <!-- end blueprint background -->
</div>

<ng-template #firstNameRequired>
  <ng-container *ngIf="(formData.controls['firstName'].dirty || formData.controls['firstName'].touched) && formData.controls['firstName'].hasError('required')">
    <small class="signup-error mat-error d-block w-100">
      <ng-container i18n>*First name is a required field</ng-container>
    </small>
  </ng-container>
  <ng-container *ngIf="(formData.controls['firstName'].dirty || formData.controls['firstName'].touched) && formData.controls['firstName'].hasError('pattern')">
    <small class="signup-error mat-error d-block w-100">
      <ng-container i18n>*This first name is not allowed</ng-container>
    </small>
  </ng-container>
</ng-template>
<ng-template #lastNameRequired>
  <ng-container *ngIf="(formData.controls['lastName'].dirty || formData.controls['lastName'].touched)  && formData.controls['lastName'].hasError('required')">
    <small class="signup-error mat-error d-block w-100">
      <ng-container i18n>*Last name is a required field</ng-container>
    </small>
  </ng-container>
  <ng-container *ngIf="(formData.controls['lastName'].dirty || formData.controls['lastName'].touched)  && formData.controls['lastName'].hasError('pattern')">
    <small class="signup-error mat-error d-block w-100">
      <ng-container i18n>*This last name is not allowed</ng-container>
    </small>
  </ng-container>
</ng-template>
<ng-template #emailRequired>
  <ng-container *ngIf="(formData.controls['email'].dirty || formData.controls['email'].touched) && formData.controls['email'].errors">
    <small *ngIf="formData.controls['email'].errors.required" class="signup-error mat-error d-block w-100">
      <ng-container i18n>*Email is a required field</ng-container>
    </small>
  </ng-container>
</ng-template>
<ng-template #emailInvalid>
  <ng-container *ngIf="(formData.controls['email'].dirty || formData.controls['email'].touched) && formData.controls['email'].errors">
    <small *ngIf="formData.controls['email'].invalid && !formData.controls['email'].errors.required" class="signup-error mat-error d-block w-100">
      <ng-container i18n>*Please enter a valid email address</ng-container>
    </small>
  </ng-container>
</ng-template>
<ng-template #passwordRequired>
  <ng-container *ngIf="(formData.controls['password'].dirty || formData.controls['password'].touched) && formData.controls['password'].errors">
    <small *ngIf="formData.controls['password'].errors.required" class="signup-error mat-error d-block w-100">
      <ng-container i18n>*Password is a required field</ng-container>
    </small>
  </ng-container>
</ng-template>
<ng-template #passwordTooShort>
  <ng-container *ngIf="(formData.controls['password'].dirty || formData.controls['password'].touched) && formData.controls['password'].errors">
    <small *ngIf="formData.controls['password'].errors.minlength && !formData.controls['password'].errors.required" class="signup-error mat-error d-block w-100">
      <ng-container i18n>*Password should be at least 8 characters long</ng-container>
    </small>
  </ng-container>
</ng-template>
<ng-template #passwordInvalid>
  <ng-container *ngIf="(formData.controls['password'].dirty || formData.controls['password'].touched) && formData.controls['password'].errors">
    <small *ngIf="formData.controls['password'].errors.pattern && !formData.controls['password'].errors.required && !formData.controls['password'].errors.minlength" class="signup-error mat-error d-block w-100">
      <ng-container i18n>*Password should contain upper and lower case letters, numbers and special characters</ng-container>
    </small>
  </ng-container>
</ng-template>
<ng-template #confirmPasswordRequired>
  <ng-container *ngIf="(formData.controls['confirmPassword'].dirty || formData.controls['confirmPassword'].touched) && formData.controls['confirmPassword'].errors">
    <small *ngIf="formData.controls['confirmPassword'].errors.required" class="signup-error mat-error d-block w-100">
      <ng-container i18n>*Confirm Password is a required field</ng-container>
    </small>
  </ng-container>
</ng-template>
<ng-template #confirmPasswordMismatch>
  <ng-container *ngIf="(formData.controls['confirmPassword'].dirty || formData.controls['confirmPassword'].touched) && formData.controls['confirmPassword'].errors">
    <small *ngIf="formData.controls['confirmPassword'].errors.MatchPassword && !formData.controls['confirmPassword'].errors.required" class="signup-error mat-error d-block w-100">
      <ng-container i18n>*Passwords do not match</ng-container>
    </small>
  </ng-container>
</ng-template>
<ng-template #geographicalAgreement>
  <ng-container *ngIf="(formData.controls['country'].dirty || formData.controls['country'].touched) && formData.controls['country'].errors">
    <small class="signup-error mat-error d-block w-100 text-center">
      <ng-container i18n>*Please tick the geographical area agreement</ng-container>
    </small>
  </ng-container>
</ng-template>
<ng-template #recaptchaRequired>
  <ng-container *ngIf="missingRecaptcha">
    <small class="w-100 text-center signup-error mat-error">
      <ng-container i18n>*Recaptcha is a required field</ng-container>
    </small>
  </ng-container>
</ng-template>
