<div class="container-fluid px-0">
  <div class="blueprint-background">
    <div class="row mt-4 clearfix">
      <div class="offset-md-4 offset-lg-4 mt-5 mb-5 h-100 col-md-4 col-lg-4 container fix-gutter">
        <div class="row justify-content-center mat-elevation-z2">
          <form (ngSubmit)="verify()" *ngIf="!isFormSubmitted; else formSubmitted">
            <div class="row d-flex justify-content-center align-items-center mx-0">
              <div class="row d-flex align-items-center justify-content-center mx-0">
                <span class="title">
                  <h2 class="my-0" i18n>Account Activation</h2>
                </span>
              </div>

              <div class="col-12 heading mt-2 mb-4 text-center" i18n>
                To activate your account, please select the button below:
              </div>

              <div class="col-12" *ngIf="enableRecaptch">
                <div class="row my-2 mx-0 w-100">
                  <div class="col-12 heading text-center">
                    <div class="recaptcha-overlay"></div>
                    <re-captcha id="recaptcha" theme="{{recaptchaTheme}}" (resolved)="resolved($event)" required></re-captcha>
                    <small class="w-100 signup-error mat-error" *ngIf="missingRecaptcha" i18n>
                      *Recaptcha is a required field
                    </small>
                  </div>
                </div>
              </div>

              <div class="col-12 heading my-2 mx-auto">
                <button
                  type="submit"
                  mat-raised-button color="primary"
                  class="mat-raised-button mat-primary orange-gradient w-100 mt-2 py-1 main-button"
                  i18n
                >
                  ACTIVATE ACCOUNT
                </button>
              </div>
            </div>
          </form>

          <ng-template #formSubmitted>
            <form novalidate class="w-100" *ngIf="verifySuccess">
              <div class="row d-flex align-items-center justify-content-center mx-0">
                <span class="mr-2"> <!--<mat-icon svgIcon="verified" i18n-matTooltip matTooltip="Successful" ></mat-icon>-->
                  <img src="../../../assets/chainex/images/icons/check.png" loading="lazy">
                </span>

                <span class="title">
                  <h2 class="my-0" i18n>Account Activation</h2>
                </span>
              </div>

              <div class="col-12 heading mt-2 mb-4 text-center" i18n>
                Your account has been activated successfully!
              </div>

              <div class="col-12 heading mt-2 mb-4 text-center" i18n>
                Thank you for signing up with ChainEX, click the link below to login with your new account.
              </div>

              <div class="col-12 heading mt-2 mb-2">
                <a routerLink="/login">
                  <button
                    mat-raised-button color="primary"
                    class="mat-raised-button mat-primary orange-gradient w-100 mt-2 py-1 main-button"
                    i18n
                  >
                    SIGN IN
                  </button>
                </a>
              </div>
            </form>

            <form novalidate class="w-100" *ngIf="verifyold">
              <div class="row d-flex align-items-center justify-content-center mx-0">
                <span class="icon">
                    <mat-icon svgIcon="warning" i18n-matTooltip matTooltip="Warning" ></mat-icon>
                </span>

                <span class="title">
                  <h2 class="my-0" i18n>Account Activation</h2>
                </span>
              </div>

              <div class="col-12 heading mt-2 mb-4 text-center" i18n>
                Your account has already been activated. Please login below.
              </div>

              <div class="col-12 heading mt-2 mb-2">
                <a routerLink="/login">
                  <button
                    mat-raised-button
                    color="primary"
                    class="mat-raised-button mat-primary orange-gradient w-100 mt-2 py-1 main-button"
                    i18n
                  >
                    SIGN IN
                  </button>
                </a>
              </div>
            </form>

            <form novalidate class="w-100" *ngIf="verifyUsed">
              <div class="row d-flex align-items-center justify-content-center mx-0">
                <span class="icon">
                    <mat-icon svgIcon="warning" i18n-matTooltip matTooltip="Warning" ></mat-icon>
                </span>

                <span class="title">
                  <h2 class="my-0" i18n>Account Activation</h2>
                </span>
              </div>

              <div class="col-12 heading mt-2 mb-4 text-center" i18n>
                This activation link has already been used or its request has expired.
              </div>

              <div class="col-12 heading mt-2 mb-4 text-center" i18n>
                Please try logging in to you account again or contact support.
              </div>

              <div class="col-12 heading mt-2 mb-2">
                <a routerLink="/login">
                  <button
                    mat-raised-button
                    color="primary"
                    class="mat-raised-button mat-primary orange-gradient w-100 mt-2 py-1 main-button"
                    i18n
                  >
                    SIGN IN
                  </button>
                </a>
              </div>
            </form>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
