<div [ngClass]="darkTheme ? 'dark-theme-background' : 'light-theme-background'" class="container-fluid px-0">
  <div class="blueprint-background">
    <!-- start blueprint background -->
    <div class="row mx-0">
      <div class="container mt-0 mt-lg-4 mb-2 mb-lg-5 pt-2 pt-lg-4 pb-2 px-0 mx-auto">
        <div class="col-12 col-lg-10 px-0 ml-auto">
          <ng-container *ngIf="login">
            <ng-container *ngTemplateOutlet="errorMessages"></ng-container>
          </ng-container>

          <div [ngClass]="darkTheme ? 'dark-box':'light-box'" class="mx-2 px-4 py-4" *ngIf="login">
            <div class="row map-background"> <!-- start map background -->
              <div class="col-12 col-lg-6 d-none d-lg-flex align-items-center justify-content-center">
                <img *ngIf="darkTheme" src="../../../assets/chainex/images/pages/auth/login/dark-mode/login.svg"
                  class="ml-5 mt-4 signin-image">
                <img *ngIf="!darkTheme" src="../../../assets/chainex/images/pages/auth/login/light-mode/login.svg"
                  class="ml-5 mt-5 signin-image">
              </div>

              <div class="col-12 col-lg-6">
                <div class="responsive-map row">
                  <div class="col-12 col-lg-6 d-flex d-lg-none align-items-center justify-content-center">
                    <img *ngIf="darkTheme" src="../../../assets/chainex/images/pages/auth/login/dark-mode/login.svg"
                      class="mx-0 pt signin-image">
                    <img *ngIf="!darkTheme" src="../../../assets/chainex/images/pages/auth/login/light-mode/login.svg"
                      class="mx-0 pt-5 signin-image">
                  </div>

                  <div class="d-none d-lg-block col-2"></div>

                  <div class="col-12 col-lg-10 ml-auto pt-2 mb-3 mb-lg-5">
                    <h2 class="orange-text text-center pr-0 pr-lg-4 my-2 my-lg-4"><b>Welcome Back!</b></h2>
                    <h4 class="py-2 py-lg-3 pr-0 pr-lg-4 my-2">Sign in to your account</h4>
                  </div>
                </div>

                <form novalidate (ngSubmit)="onSubmit()" [formGroup]="loginForm">
                  <div class="row ml-md-auto pr-0 pr-lg-4">
                    <div class="col-2 col-sm-1 col-lg-2 d-flex align-items-center">
                      <img class="d-block mb-4 mx-auto icon-container"
                        src="../../../assets/chainex/images/pages/auth/signup/light-mode/email.svg">
                    </div>
                    <div class="col-10 col-sm-11 col-lg-10 mb-4">
                      <input [ngClass]="darkTheme ? 'dark-input' : 'light-input'" class="signup-input w-100 pl-2"
                        placeholder="Email" type="email" formControlName="email" name="email" id="email" required
                      />
                      <ng-container
                        *ngIf="(loginForm.controls['email'].dirty || loginForm.controls['email'].touched) && loginForm.controls['email'].errors">
                        <small class="signup-error mat-error" *ngIf="loginForm.controls['email'].errors.required"
                          i18n>*Email is a required field</small>
                        <small class="signup-error mat-error"
                          *ngIf="loginForm.controls['email'].invalid && !loginForm.controls['email'].errors.required"
                          i18n>*Please enter a valid email address</small>
                      </ng-container>
                    </div>

                    <div class="col-2 col-sm-1 col-lg-2 d-flex align-items-center">
                      <img
                        class="d-block mx-auto icon-container"
                        src="../../../assets/chainex/images/pages/auth/signup/light-mode/password.svg"
                      >
                    </div>
                    <div class="col-10 col-sm-11 col-lg-10 mb-2">
                      <input #passwordInput class="signup-input w-100 pl-2"
                        [ngClass]="darkTheme ? 'dark-input' : 'light-input'" placeholder="Password" i18n-placeholder
                        type="password" formControlName="password" name="password" id="password" required
                      />
                      <ng-container
                        *ngIf="(loginForm.controls['password'].dirty || loginForm.controls['password'].touched) && loginForm.controls['password'].errors"
                      >
                        <small
                          class="signup-error mat-error"
                          *ngIf="loginForm.controls['password'].errors.required"
                          i18n
                        >
                          *Password is a required field
                        </small>
                      </ng-container>
                    </div>

                    <div class="d-none d-lg-block col-2 col-lg-2"></div>
                    <div class="col-12 col-lg-10" *ngIf="enableRecaptch">
                      <div class="row px-0 ml-0 my-2 w-100">
                        <div class="col-12 px-0 heading">
                          <div class="recaptcha-overlay"></div>
                          <re-captcha id="recaptcha" theme="{{recaptchaTheme}}" (resolved)="resolved($event)" required></re-captcha>
                          <small class="w-100 text-center signup-error mat-error" *ngIf="missingRecaptcha" i18n>
                            *Recaptcha is a required field
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="d-none d-lg-block col-2"></div>
                    <div class="col-12 col-lg-10 ml-auto">
                      <div class="row mb-3">
                        <div class="col-12 ml-auto d-flex flex-column align-items-center">
                          <button
                            type="submit"
                            class="mat-raised-button mat-primary orange-gradient w-100 my-2 py-1 main-button"
                            i18n
                          >
                            SIGN IN
                          </button>

                          <div class="row w-100 mb-0 alert alert-danger" *ngIf="failedLogin">
                            <div class="col-12">
                              <p class="mb-0" [innerHTML]="loginFailedMessage|i18n"></p>
                            </div>
                          </div>

                          <span class="d-block mt-3">No account yet? <a routerLink="/register">Sign up here</a></span>
                          <span class="d-block mb-0 mb-lg-5 py-3"><a routerLink="/forgot">Forgot Password?</a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> <!-- end map background -->
          </div>
        </div>

        <div class="col-12 col-md-10 col-lg-7 col-xl-6 mx-auto px-2 px-sm-4" *ngIf="!login">
          <ng-container *ngTemplateOutlet="errorMessages"></ng-container>

          <div class="row mx-2 py-4" [ngClass]="darkTheme ? 'dark-box':'light-box'" *ngIf="emailNotVerified">
            <form novalidate class="w-100">
              <div class="col-12 d-flex align-items-center justify-content-center heading mt-2 mb-2">
                <span class="icon">
                  <mat-icon svgIcon="warning" i18n-matTooltip matTooltip="Warning"></mat-icon>
                </span>
                <span class="orange-text font-weight-bold">
                  <h2 class="my-0" i18n>Account not activated</h2>
                </span>
              </div>
              <div class="col-12 heading text-center mt-2 mb-2">
                Please follow the instructions emailed to you to activate your account or resend the activation email
                below.
              </div>
              <div class="col-12 col-lg-8 mx-auto mt-4 mb-2">
                <button type="button" class="mat-raised-button mat-accent w-100 mt-2 py-1 main-button white-text"
                  (click)="reactivateLogin()" i18n>SIGN IN</button>
              </div>
              <div class="col-12 col-lg-8 mx-auto mt-2 mb-2">
                <button type="button"
                  class="mat-raised-button mat-primary orange-gradient w-100 mt-2 py-1 main-button"
                  (click)="resendActivationEmail()" i18n>
                  RESEND ACTIVATION EMAIL
                </button>
              </div>
            </form>
          </div>

          <div class="row mx-2 py-4" [ngClass]="darkTheme ? 'dark-box':'light-box'" *ngIf="deactivatedAccount">
            <form novalidate class="w-100">
              <div class="col-12 d-flex align-items-center justify-content-center heading mt-2 mb-2">
                <span class="icon">
                  <mat-icon svgIcon="warning" i18n-matTooltip matTooltip="Warning"></mat-icon>
                </span>
                <span class="font-weight-bold orange-text">
                  <h2 class="my-0" i18n>Account Deactivated</h2>
                </span>
              </div>
              <h4 i18n>Your account has been deactivated.<br>Would you like to reactivate your account?</h4>
              <div class="col-12 col-lg-8 mx-auto mt-4 mb-2">
                <button type="button"
                  class="mat-raised-button mat-primary orange-gradient w-100 mt-2 py-1 main-button"
                  (click)="sendReactivationEmail()" i18n>
                  SEND RE-ACTIVATION EMAIL
                </button>
              </div>
              <div class="col-12 col-lg-8 mx-auto my-2">
                <a routerLink="/">
                  <button type="button"
                    class="mat-raised-button mat-primary orange-gradient w-100 mt-2 py-1 main-button"
                    i18n>HOME</button>
                </a>
              </div>
            </form>
          </div>
          <!-- TFA -->
          <div class="row mx-2 py-4" [ngClass]="darkTheme ? 'dark-box':'light-box'" *ngIf="needs2fa">
            <form novalidate class="w-100 px-3" (ngSubmit)="submit2FA()" [formGroup]="tokenData">
              <div class="col-12 d-flex align-items-center justify-content-center heading mt-2 mb-2">
                <span class="icon">
                  <mat-icon svgIcon="twofa" i18n-matTooltip matTooltip="Google Authentication"></mat-icon>
                </span>
                <span class="orange-text">
                  <h2 id="tfa-heading" i18n class="2fa-heading font-weight-bold mb-0">Two Factor Authentication</h2>
                </span>
              </div>
              <span class="grey-text d-block text-center my-3" i18n>
                Please enter the code from your authenticator app
              </span>
              <div class="col-12 mx-auto my-2 py-3 d-flex flex-column align-items-center">
                <app-token-input #token [formControlInput]="tokenData.controls.token" (changeOrPaste)="on2faChange($event)" ></app-token-input>
                <!--input type="tel" name="token" id="token" [ngClass]="darkTheme ? 'dark-input' : 'light-input'"
                class="col-9 col-sm-auto py-1 text-center d-block" #token
                formControlName="token" autocomplete="off"
                maxlength="6"
                placeholder="000000" (keyup)="on2faChange($event)" (paste)="on2faChange($event)"-->
              </div>
              <div class="col-12 col-lg-11 mx-auto mt-2 mb-3" *ngIf="failedLogin">
                <div class="alert alert-danger">
                  <p class="mb-0 text-center" innerHTML="{{loginFailedMessage|i18n}}"></p>
                </div>
              </div>
              <div class="col-12 col-lg-11 mx-auto mt-2 mb-3">
                <button type="submit" [disabled]="submitted"
                  class="mat-raised-button mat-primary orange-gradient w-100 mt-2 py-1 main-button" i18n>
                  AUTHENTICATE</button>
              </div>
              <div class="col-12 py-3">
                <span class="d-block disable-text" i18n>
                  Lost your 2FA token? <a href="" routerLink="/disabletwofa">Click here</a> to request disabling of 2FA or
                  <a target="_blank" href="{{getSupportUrl()}}">contact support here</a>.
                </span>
              </div>
            </form>
          </div>
          <!-- IP Check -->
          <div class="row py-4" [ngClass]="darkTheme ? 'dark-box':'light-box'" *ngIf="ipCheck">
            <form novalidate class="w-100 px-3" (ngSubmit)="submit2FA()" [formGroup]="tokenData">
              <div class="col-12 d-flex align-items-center justify-content-center heading mt-2 mb-2">
                <span class="icon">
                  <mat-icon svgIcon="password" i18n-matTooltip></mat-icon>
                </span>
                <span class="orange-text">
                  <h2 i18n class="2fa-heading font-weight-bold mb-0">New IP detected</h2>
                </span>
              </div>
              <span class="grey-text d-block text-center my-3" i18n>
                You are signing in from an unknown IP address.
                In order to sign-in please verify by following the instructions which have been emailed to you.
              </span>
              <div class="col-12 col-lg-11 mx-auto mt-2 mb-3">
                <button (click)="ipCheck = !ipCheck; login = !login"
                  class="mat-raised-button mat-primary orange-gradient w-100 mt-2 py-1 main-button"
                  i18n>RETURN TO LOGIN
                </button>
              </div>
              <div class="col-12 pt-3">
                <label class="d-block text-center" i18n>
                  Not receiving an email or have any questions? <a target="_blank"
                    href="{{getSupportUrl()}}">Contact support here.</a>
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div> <!-- end blueprint background -->
</div>

<ng-template #errorMessages>
  <div class="pb-0 pl-0 pr-0 row mx-2" *ngIf="forcedLogout">
    <div class="col-12 alert alert-warning pt-3 alert-warning">
      <div class="mb-0" i18n>You were automatically logged out due to inactivity.</div>
    </div>
  </div>
  <div class="pb-0 pl-0 pr-0 row mx-2" *ngIf="apiErrorLogout">
    <div class="col-12 alert alert-warning pt-3 alert-warning">
      <div class="mb-0">{{ loggedOutReason }}</div>
    </div>
  </div>
</ng-template>
