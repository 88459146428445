import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { RouterHelper } from 'src/app/core/router-helper';
import { environment } from 'src/environments/environment';
import { AutoLogoutService } from 'src/app/core/services/auto-logout.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { Auth2FA } from 'src/app/core/interfaces/auth-2fa';
import { EventHubService } from 'src/app/core/services/event-hub.service';
import { AuthService } from '../auth.service';
import { User } from 'src/app/core/interfaces/user';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';

import { StoreService, SubscriptionLike } from 'src/app/core/services/store.service';
import { TokenInputComponent } from 'src/app/shared/token-input/token-input.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy  {
  @ViewChild('token') tokenInput: TokenInputComponent;

  // state
  darkTheme: boolean = false;
  enableRecaptch: boolean = true;
  siteName: string = environment.config.EXCHANGE_NAME_L;
  recaptchaTheme: string = 'light';
  emailNotVerified: boolean;
  needs2fa: boolean;
  ipCheck: boolean;
  login: boolean;
  submitted: boolean = false;
  deactivatedAccount: boolean;

  // models / data
  loginForm: FormGroup;
  tokenData: FormGroup;
  user: User;
  public verifyEmail: string;
  deactivatedUserId: string;

  // errors
  failedLogin: boolean;
  loginFailedMessage: string;

  returnRoute: string;

  recaptcha: string;
  missingRecaptcha: boolean;

  forcedLogout: boolean = false;
  apiErrorLogout: boolean = false;

  loggedOutReason: string = '';

  siteKey: string = environment.config.RECAPTCHA_SITE_KEY;

  private subs: SubscriptionLike[] = [];

  constructor(
    private auth: AuthService,
    private autoLogout: AutoLogoutService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private eventhub: EventHubService,
    private sessionStorage: SessionStorageService,
    private store: StoreService,
    private i18n: I18n,
    public snackbar: MatSnackBar,
    private translateService: I18nTranslationService
  ) {
    this.subs.push(this.eventhub.listen().subscribe((m: any) => {
      this.onEvent(m);
    }));
  }

  ngOnInit() {
    // state
    this.emailNotVerified = false;
    this.needs2fa = false;
    this.login = true;
    this.submitted = false;
    this.deactivatedAccount = false;

    this.recaptcha = '';
    this.missingRecaptcha = false;

    // models / data
    this.setupForms();
    this.user = { email: '', password: '', sessionId: '', isAuth: '', 'g-recaptcha-response': '' };
    this.verifyEmail = '';
    this.deactivatedUserId = '';

    // errors
    this.resetErrors();

    this.returnRoute = this.route.snapshot.paramMap.get('returnroute') || '/';
    this.sessionStorage.store('returnRoute', this.returnRoute);

    this.apiErrorLogout = false;
    if (this.sessionStorage.get('API_ERROR_LOGOUT') === true) {
      this.apiErrorLogout = true;
      this.sessionStorage.store('API_ERROR_LOGOUT', false);
      this.sessionStorage.remove('TOKEN');
      this.loggedOutReason = this.translateService.translateResponse(this.sessionStorage.get('LOGGED_OUT_REASON'));
    } else {
      this.forcedLogout = false;
      if (this.sessionStorage.get('FORCED_LOGOUT') === true) {
        this.sessionStorage.remove('FORCED_LOGOUT');
        this.forcedLogout = true;
      } else {
        this.forcedLogout = false;
      }
    }

    this.subs.push(this.sessionStorage.observe('THEME').subscribe(
      data => {
        this.recaptchaTheme = (data === 'dark-theme' ? 'dark' : 'light');
        this.darkTheme = (data === 'dark-theme');
      } ));

    this.subs.push(this.router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationStart) {
        if (routerEvent.url.indexOf('/login') === -1) {
          this.sessionStorage.remove('FORCED_LOGOUT');
        }
      }
    }));

  }

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public resolved(captchaResponse: string) {
    this.recaptcha = captchaResponse;
    this.missingRecaptcha = false;
  }

  onEvent(event: any) {
    switch (event) {
      case 'reset-password-success':
        this.snackbar.open(this.i18n('Your password has been reset. You can now login.'),
            this.i18n('Close'), {duration: 2000});
        break;
    }
  }

  setupForms() {
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.email]),
      password: new FormControl('', Validators.required),
      remember_me: new FormControl()
    });

    this.tokenData = this.fb.group({
      token: new FormControl('', Validators.required),
    });
  }

  resetErrors() {
    this.needs2fa = false;
    this.failedLogin = false;
    this.loginFailedMessage = '';
    this.login = true;
  }

  validateForm() {
    let valid = true;
    const controls = this.loginForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        this.loginForm.controls[name].markAsDirty();
        valid = false;
      }
    }
    if (this.enableRecaptch && this.recaptcha === '') {
      this.missingRecaptcha = true;
      valid = false;
    }
    return valid;
  }

  onSubmit() {
    this.resetErrors();

    if (this.validateForm()) {
      this.user = {
        email: this.loginForm.get('email').value.toLowerCase(),
        password: this.loginForm.get('password').value,
        sessionId: '',
        isAuth: '',
        'g-recaptcha-response': this.recaptcha
      };

      this.auth.login(this.user)
        .subscribe((response: any) => {
          setTimeout(() => this.processLogin(response), 1);
        });
    }
  }

  processLogin(response: any, fromTfa?: boolean) {
    if (response.response === 'success') {
      this.loggedInSuccess(response);
    } else if ((response.response === 'redirect') && (response.action !== 'emailNotConfirmed')) {
      this.resetErrors();
      this.login = false;
      this.needs2fa = response.type === 'twofa';
      if (this.needs2fa) {
        this.sessionStorage.store('PROFILE_EMAIL', this.user.email); // store email in case user needs 2fa disabled
      }
      setTimeout (() => {
        if (this.tokenInput) {
          this.tokenInput.focus();
        }
      }, 0);
      this.ipCheck = response.type === 'ip';
      this.deactivatedAccount = false;
      if (fromTfa) {
        this.tokenData.controls.token.enable();
        this.submitted = false;
        this.tokenData.reset();
      }
    } else if ((response.response === 'redirect') && (response.action === 'emailNotConfirmed')) {
      this.loginForm.reset();
      this.emailNotVerified = true;
      this.login = false;
      this.deactivatedAccount = false;
      this.verifyEmail = this.user.email;
    } else if (response.response === 'failure') {
      this.loginForm.reset();
      this.failedLogin = true;
      this.deactivatedAccount = false;
      this.loginFailedMessage = response.reason;
      if (this.enableRecaptch) {
        try {
          grecaptcha.reset();
        } catch (e) {
        }
      }
      if (fromTfa) {
        this.needs2fa = false;
        this.login = true;
        this.tokenData.controls.token.enable();
        this.submitted = false;
        this.tokenData.reset();
      }
    } else if (response.response === 'deactivate') {
      this.loginForm.reset();
      this.emailNotVerified = false;
      this.login = false;
      this.deactivatedAccount = true;
      this.deactivatedUserId = response.closed;
    }
  }

  submit2FA() {
    if (!this.submitted) {
      const data: Auth2FA = {
        email: this.loginForm.get('email').value.toLowerCase(),
        password: this.loginForm.get('password').value,
        // replace unicode U+2000 (white space) and regular spaces
        token: this.tokenData.get('token').value.replace(/ | /g, '')
      };
      this.tokenData.controls.token.disable();
      this.submitted = true;
      this.auth.verify2fa(data).subscribe((response: any) => {
        this.processLogin(response, true);
      });
    }
  }

  loggedInSuccess(response: any) {
    const remember_me: boolean = this.loginForm.get('remember_me').value ? true : false;
    this.store.logOff();
    if (response.notifications) {
      // Might not be valid JSON...
      try {
        const notificationJSON = JSON.parse(response.notifications);
        const securityJSON = JSON.parse(response.security_settings);
        const withdrawSettings = securityJSON.withdrawals;
        this.sessionStorage.store('NOTIFICATION_LOCATION',
          (notificationJSON.location ? notificationJSON.location : '1'));
        this.sessionStorage.store('WITHDRAW_SETTINGS',
          (withdrawSettings ? withdrawSettings : {}));
      } catch (e) { }
    }
    this.sessionStorage.store('PROFILE_VERIFICATION_LEVEL', response.verificationLevel);
    this.sessionStorage.store('PROFILE_COUNTRY', response.country);
    this.sessionStorage.store('PROFILE_EMAIL', response.email);
    this.sessionStorage.store('PROFILE_FIRST_NAME', response.first_name);
    this.sessionStorage.store('PROFILE_LAST_NAME', response.last_name);
    this.sessionStorage.store('PROFILE_BALANCE_SETTINGS', JSON.parse(response.balance_settings));
    this.sessionStorage.store('PROFILE_DEFAULT_CARD', response.default_card);
    this.sessionStorage.store('LOGGED_TIMEZONE', response.timezone);
    this.sessionStorage.store('REMEMBER_ME', remember_me);
    this.sessionStorage.store('IEO_COINS', response.ieo_coins);
    this.sessionStorage.store('LOGGED_IN', 'logged_in');
    this.sessionStorage.store('THEME', (response.dark_theme === '0' ? 'light-theme' : 'dark-theme'));
    this.sessionStorage.store('FIRST_LOGIN', response.first_login);

    this.store.login();

    this.autoLogout.startIfNotStarted();
    RouterHelper.goToRedirect(this.router, this.route, response.verificationLevel);

    if (response.verification_type_required) {
      // example: response.verification_type_required === compliance-responses-pending
      this.eventhub.emit(response.verification_type_required);
      this.sessionStorage.store('verificationTypeRequired', response.verification_type_required);
    }
    if (!response.fully_verified) {
      this.eventhub.emit('proof-of-residence-requred');
    }
  }

  on2faChange(value: string) {
    if (value.length === 6) {
      this.submit2FA();
    }
  }

  reactivateLogin() {
    this.resetErrors();
    this.login = true;
    this.emailNotVerified = false;
  }

  resendActivationEmail() {
    this.auth.resendActivationEmail(this.verifyEmail)
      .subscribe((response: any) => {
        this.snackbar.open(this.translateService.translateResponse(response.reason),
            this.i18n('Close'), {duration: 2000});
      });
  }

  sendReactivationEmail() {
    this.auth.sendReactivationEmail(this.deactivatedUserId, this.user.email, this.user.password)
      .subscribe((response: any) => {
        this.snackbar.open(this.translateService.translateResponse(response.reason),
            this.i18n('Close'), {duration: 2000});
      });
  }

  get token() {
    return this.tokenData.get('token');
  }

}
