import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormGroup, FormBuilder, FormControl, Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';


import { UserRegistration } from '../../core/interfaces/auth';
import { AuthService } from '../auth.service';
import { environment } from '../../../environments/environment';

import { PasswordValidator } from '../../core/validators/password.validator';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {
  affiliateCounter: number = 0;
  darkTheme: boolean = false;
  datalength: number;

  coinTypes: {
    name: string;
    selected: boolean;
    priority: number;
    icon: string;
    coin: number;
  } [] = [];

  disableinput: boolean = false;
  rawdata: any;
  registerSuccess: boolean;
  registerSuccessStep1: boolean;

  selectedCoin: {
    name: string;
    selected: boolean;
    priority: number;
    icon: string;
    coin: number;
  } [] = [];

  // models / data
  BASE_URL: string = environment.config.BACKEND_URL;
  confirmPasswordTooltipHover: boolean;
  emailTooltipHover: boolean;
  enableRecaptch: boolean = true;
  failedRegister: boolean;
  failedMessage: string;
  formData: FormGroup;
  maxInputSize: number;
  missingRecaptcha: boolean;
  passwordStrengthValidator: Function = PasswordValidator.PasswordStrength;
  passwordTooltipHover: boolean;
  recaptcha: string;
  registeredEmail: string;
  recaptchaTheme: string = 'light';
  referredBy: string;
  siteKey: string = environment.config.RECAPTCHA_SITE_KEY;
  siteName: string = environment.config.EXCHANGE_NAME_L;
  user: UserRegistration;

  campaignTypeSelection: FormControl = new FormControl('');
  campaignTypes: string[] = [
    'Flyer',
    'Radio',
    'Friend',
    'Google',
    'Twitter',
    'Telegram',
    'Facebook',
    'Instagram',
    'Other'
  ];

  private subs: SubscriptionLike;

  public resolved(captchaResponse: string) {
    this.recaptcha = captchaResponse;
    this.missingRecaptcha = false;
  }

  constructor(
    private fb: FormBuilder,
    private translateService: I18nTranslationService,
    public snackbar: MatSnackBar,
    private i18n: I18n,
    private auth: AuthService,
    private sessionStorage: SessionStorageService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    if (this.siteName === 'burnx') {
      this.getAvailableAffiliates();
    }

    const campaign = this.route.snapshot.queryParamMap.get('type');
    if (!!campaign) {
      const campaignType = this.campaignTypes.find(type => type.toLowerCase() === campaign.toLowerCase());
      if (!!campaignType) {
        this.campaignTypeSelection.setValue(campaignType);
        this.campaignTypeSelection.disable();
      }
    }

    this.referredBy = this.route.snapshot.paramMap.get('referralcode');
    if (!!this.referredBy && this.referredBy !== '') {
      this.sessionStorage.store('referredBy', this.referredBy);
    } else {
      this.referredBy = this.sessionStorage.get('referredBy');
    }

    this.emailTooltipHover = false;
    this.passwordTooltipHover = false;
    this.confirmPasswordTooltipHover = false;
    this.registerSuccessStep1 = false;
    this.failedRegister = false;
    this.failedRegister = false;
    this.missingRecaptcha = false;
    this.failedMessage = '';
    this.registeredEmail = '';
    this.recaptcha = '';
    this.user = {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      password2: '',
      'g-recaptcha-response': '',
      referredby: '',
      campaign_type: ''
    };
    this.setupForm();

    this.subs = this.sessionStorage.observe('THEME')
      .subscribe(data => {
        this.recaptchaTheme = (data === 'dark-theme' ? 'dark' : 'light');
        this.darkTheme = (data === 'dark-theme');
      });
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  getAvailableAffiliates() {
    this.auth.getAffiliates()
      .subscribe((affilliateData: any) => {
        if (affilliateData.response === 'success') {
          this.rawdata = affilliateData.data;
          this.maxInputSize = this.rawdata.length;
          if (this.rawdata.length > 3) {
            this.maxInputSize = 4;
          }
          this.reformatAffilliates();
        } else if (affilliateData.response === 'failure') {
          this.failedMessage = affilliateData.reason;
        }
      });
  }

  reformatAffilliates() {
    for ( let i = 0; i < this.rawdata.length; i++ ) {
      const transferData: any = {
        name: 'test',
        priority: -1,
        selected: 'false',
        icon: '',
        coin: -1
      };
      transferData.name = this.rawdata[i].name;
      transferData.selected = false;
      transferData.priority = -1;
      transferData.coin = this.rawdata[i].id;
      transferData.icon = this.rawdata[i].icon;
      this.coinTypes.push(transferData);
    }
  }

  selectMajorCoin() {
    for ( let i = 0; i < this.coinTypes.length; i++ ) {
      if ( this.coinTypes[i].priority === 1 ) {
        this.selectedCoin.push(this.coinTypes[i]);
        this.coinTypes.splice(i, 1);
        this.coinTypes[0].selected = true;
        this.coinTypes[0].priority = 1;
      }
    }
  }

  onSubmit1() {
    if ( this.siteName === 'burnx' ) {
      if (this.validateForm()) {
        this.user = {
          email: this.formData.get('email').value.toLowerCase(),
          first_name: this.formData.get('firstName').value,
          last_name: this.formData.get('lastName').value,
          password: this.formData.get('password').value,
          password2: this.formData.get('confirmPassword').value,
          'g-recaptcha-response': this.recaptcha,
          referredby: this.formData.get('referredBy').value,
        };
        if (this.user.referredby !== '') {
          this.auth.checkReferrerExists({'referredby' : this.user.referredby}).subscribe((referrerdata: any) => {
            if (referrerdata.response === 'success') {
              this.registerSuccessStep1 = true;
            } else {
              this.snackbar.open(this.translateService.translateResponse(referrerdata.reason),
                this.i18n('Close'), {duration: 2000});
            }
          });
        } else {
          this.registerSuccessStep1 = true;
        }
      }
    } else {
      if (this.validateForm()) {
        this.user = {
          email: this.formData.get('email').value.toLowerCase(),
          first_name: this.formData.get('firstName').value,
          last_name: this.formData.get('lastName').value,
          password: this.formData.get('password').value,
          password2: this.formData.get('confirmPassword').value,
          'g-recaptcha-response': this.recaptcha,
          referredby: this.formData.get('referredBy').value,
          campaign_type: this.campaignTypeSelection.value
        };
        this.auth.registerUser(this.user)
          .subscribe((userdata: any) => {
            if (userdata.response === 'success') {
              this.formData.reset();
              this.registeredEmail = this.user.email;
              this.failedRegister = false;
              this.registerSuccessStep1 = true;
            } else if (userdata.response === 'failure') {
              this.failedRegister = true;
              this.failedMessage = userdata.reason;
              grecaptcha.reset();
            }
          });
      }
    }
  }

  setupForm() {
    const notUrlValidator = () => (control) => {
      const urlRegex = '(((http|https)://)(www.))|(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)';
      const value = control.value;

      const regEx = new RegExp(urlRegex);
      return (regEx.test(value) ? {pattern: true} : null);
    };

    this.formData = this.fb.group({
      email: new FormControl('', [Validators.email]),
      firstName: new FormControl('', [Validators.required, notUrlValidator()]),
      lastName: new FormControl('', [Validators.required, notUrlValidator()]),
      password: new FormControl('', [Validators.minLength(8), Validators.required,
      Validators.pattern(PasswordValidator.PasswordRegex)]),
      confirmPassword: new FormControl('', [Validators.minLength(8), Validators.required]),
      country: new FormControl('', Validators.required),
      referredBy: new FormControl(this.referredBy),
    }, {
      validator: PasswordValidator.MatchPassword
    });
    if (this.referredBy) {
      this.formData.controls.referredBy.disable();
    }
  }

  validateForm() {
    let valid = true;
    const controls = this.formData.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        this.formData.controls[name].markAsDirty();
        valid = false;
      }
    }
    if (this.recaptcha === '') {
      this.missingRecaptcha = true;
      valid = false;
    }
    return valid;
  }

  onSubmit() {
    if (this.validateForm()) {
      if ( Number(this.selectedCoin.length) === Number(0) ) {
        this.snackbar.open(this.translateService.translateResponse('Please select between 1 and 4 Affiliate projects'),
          this.i18n('Close'), {duration: 2000});
      } else {
        this.auth.registerUser(this.user)
          .subscribe((userdata: any) => {
            if (userdata.response === 'success') {
              this.formData.reset();
              this.registeredEmail = this.user.email;
              let filterdata: any = this.coinTypes;
              filterdata.push(this.selectedCoin[0]);
              filterdata = filterdata.filter(function (item: any) {
                return item.priority !== -1;
              });
              const data: object = {'userEmail': this.registeredEmail, 'selectedAffiliates': filterdata};
              this.auth.addUserAffiliates(data).subscribe((userdata1: any) => {
                if (userdata1.response === 'success') {
                  this.snackbar.open(this.translateService.translateResponse('Affiliates selected successfully'),
                    this.i18n('Close'), {duration: 2000});
                  this.registerSuccess = true;
                } else if (userdata1.response === 'failure') {
                  this.failedMessage = userdata1.reason;
                }
              });
            } else if (userdata.response === 'failure') {
              this.failedRegister = true;
              this.failedMessage = userdata.reason;
              grecaptcha.reset();
            }
          });
      }
    }
  }

  reorderPriority() {
    for ( let i = 0; i < this.coinTypes.length; i++ ) {
      if ( this.coinTypes[i].priority !== -1 && (i <= this.affiliateCounter + 1) ) {
        this.coinTypes[i].priority = i + 2;
      } else {
        this.coinTypes[i].priority = -1;
      }
    }
  }

  onChange(event: any, index: any, item: any) {
    if ( this.selectedCoin.length === 1 ) {
      this.checkInputSize();
      if ( item.selected ) {
        item.selected = false;
        item.priority = -1;
        this.affiliateCounter--;
        this.coinTypes.splice(index, 1);
        this.coinTypes.splice(this.coinTypes.length - 1, 0, item);
        this.coinTypes[this.coinTypes.length - 1].selected = false;
        this.coinTypes[this.coinTypes.length - 1].priority = -1;
        this.reorderPriority();
      } else if ( this.affiliateCounter < 4 ) {
        this.coinTypes.splice(index, 1);
        this.coinTypes.splice(this.affiliateCounter - 1, 0, item);
        this.coinTypes[this.affiliateCounter - 1].selected = true;
        this.coinTypes[this.affiliateCounter - 1].priority = this.affiliateCounter + 2;
        this.affiliateCounter++;
        this.reorderPriority();
      }
    } else {
      if ( item.selected ) {
        item.selected = false;
        this.affiliateCounter--;
        this.coinTypes.splice(index, 1);
        this.coinTypes.splice(this.coinTypes.length - 1, 0, item);
        this.coinTypes[this.coinTypes.length - 1].selected = false;
        this.coinTypes[this.coinTypes.length - 1].priority = -1;
        this.reorderPriority();
      } else {
        this.checkInputSize();
        this.selectedCoin.push(item);
        this.affiliateCounter++;
        this.coinTypes.splice(index, 1);
        this.selectedCoin[0].selected = true;
        this.selectedCoin[0].priority = 1;
        this.reorderPriority();
      }
    }
  }

  onChangeTop(event: any, index: any, item: any) {
    this.checkInputSize();
    this.selectedCoin[0].selected = false;
    this.selectedCoin[0].priority = -1;
    this.coinTypes.push(item);
    this.affiliateCounter--;
    this.selectedCoin.splice(index, 1);
    if ( this.affiliateCounter >= 1 ) {
      this.selectedCoin.push(this.coinTypes[0]);
      this.affiliateCounter++;
      this.selectedCoin[0].priority = 1;
      this.coinTypes.splice( 0 , 1 );
      this.selectedCoin[0].selected = true;
      this.affiliateCounter--;
      this.reorderPriority();
    }
    this.reorderPriority();
  }

  checkInputSize() {
    if ( this.affiliateCounter >= 3 ) {
      this.disableinput = true;
    } else {
      this.disableinput = false;
    }
  }

  buildImageString(coinName: string) {
    const imageurl = this.BASE_URL + '/action/getCoin?coinIcon=' + coinName;
    return imageurl;

  }
}
