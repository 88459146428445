import { AuthRerouteGuard, NoAuthGuard } from '../core/services/auth-guard.service';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { VerifyComponent } from './verify/verify.component';
import { MetaGuard } from '@ngx-meta/core';
import { environment } from '../../environments/environment';

import { AuthModule } from './auth.module';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { title: 'Login',
  meta: {
    title: 'Log in to ' + environment.config.EXCHANGE_NAME + ' | Login To ' + environment.config.EXCHANGE_NAME + `\
 & Start Trading Today!`,
    description: `Log in to ChainEX today! Your digital asset exchange platform for securely \
trading digital assets against real-time markets! Sign in now & start trading!`
  } }, canActivate: [AuthRerouteGuard, MetaGuard] },
  { path: 'login/verify/:hash', component: VerifyComponent, data: {
    title: 'Activate Account',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - Activate Account',
      description: environment.config.EXCHANGE_NAME
    }
  }, canActivate: [NoAuthGuard, MetaGuard] },
  { path: 'login/:returnroute', component: LoginComponent, data: { title: 'Login',
  meta: {
    title: 'Log in to ' + environment.config.EXCHANGE_NAME + ' | Login To ' + environment.config.EXCHANGE_NAME + `\
 & Start Trading Today!`,
    description: `Log in to ChainEX today! Your digital asset exchange platform for securely \
trading digital assets against real-time markets! Sign in now & start trading!`
  } }, canActivate: [AuthRerouteGuard, MetaGuard] },
  { path: 'login/:returnroute/:subroute1', component: LoginComponent, data: { title: 'Login',
  meta: {
    title: 'Log in to ' + environment.config.EXCHANGE_NAME + ' | Login To ' + environment.config.EXCHANGE_NAME + `\
 & Start Trading Today!`,
    description: `Log in to ChainEX today! Your digital asset exchange platform for securely \
trading digital assets against real-time markets! Sign in now & start trading!`
  } }, canActivate: [AuthRerouteGuard, MetaGuard] },
  { path: 'login/:returnroute/:subroute1/:subroute2', component: LoginComponent, data: { title: 'Login',
  meta: {
    title: 'Log in to ' + environment.config.EXCHANGE_NAME + ' | Login To ' + environment.config.EXCHANGE_NAME + `\
 & Start Trading Today!`,
    description: `Log in to ChainEX today! Your digital asset exchange platform for securely \
trading digital assets against real-time markets! Sign in now & start trading!`
  } }, canActivate: [AuthRerouteGuard, MetaGuard] },
  { path: 'signup', component: SignupComponent, data: { title: 'Sign Up',
  meta: {
    title: 'Sign up to ' + environment.config.EXCHANGE_NAME + ' | Create a ' + environment.config.EXCHANGE_NAME + `\
 Account & Start Trading!`,
    description: `Sign up to ` + environment.config.EXCHANGE_NAME  + ` today! Your digital asset exchange ` + `\
platform for securely trading digital assets against real-time markets! Sign up now & start trading!`,
    'og:url': environment.config.APP_URL + '/register',
    'og:type': 'website',
    'og:title': 'Sign up to ' + environment.config.EXCHANGE_NAME + ` | Create a` + `\ `
+ environment.config.EXCHANGE_NAME + ' Account & Start Trading!',
    'og:description': `Sign up to ` + environment.config.EXCHANGE_NAME  + ` today! Your digital asset exchange ` + `\
platform for securely trading digital assets against real-time markets! Sign up now & start trading!`,
    'og:image': environment.config.APP_URL + 'assets/' + environment.config.EXCHANGE_NAME_L + '/favicon-96x96.png'
  } }, canActivate: [AuthRerouteGuard, MetaGuard] },
  { path: 'register', component: SignupComponent, data: { title: 'Sign Up',
  meta: {
    title: 'Sign up to ' + environment.config.EXCHANGE_NAME + ' | Create a ' + environment.config.EXCHANGE_NAME + `\
 Account & Start Trading!`,
    description: `Sign up to ` + environment.config.EXCHANGE_NAME  + ` today! Your digital asset exchange ` + `\
 platform for securely trading digital assets against real-time markets! Sign up now & start trading!`,
    'og:url': environment.config.APP_URL + '/register',
    'og:type': 'website',
    'og:title': 'Sign up to ' + environment.config.EXCHANGE_NAME + ` | Create a` + `\ `
+ environment.config.EXCHANGE_NAME + ' Account & Start Trading!',
    'og:description': `Sign up to ` + environment.config.EXCHANGE_NAME  + ` today! Your digital asset exchange ` + `\
platform for securely trading digital assets against real-time markets! Sign up now & start trading!`,
    'og:image': environment.config.APP_URL + 'assets/' + environment.config.EXCHANGE_NAME_L + '/favicon-96x96.png'
  } }, canActivate: [AuthRerouteGuard, MetaGuard] },
  { path: 'signup/:referralcode', component: SignupComponent, data: { title: 'Sign Up',
  meta: {
    title: 'Sign up to ' + environment.config.EXCHANGE_NAME + ' | Create a ' + environment.config.EXCHANGE_NAME + `\
 Account & Start Trading!`,
    description: `Sign up to ` + environment.config.EXCHANGE_NAME  + ` today! Your digital asset exchange` + `\
 platform for securely trading digital assets against real-time markets! Sign up now & start trading!`,
    'og:url': environment.config.APP_URL + '/register',
    'og:type': 'website',
    'og:title': 'Sign up to ' + environment.config.EXCHANGE_NAME + ` | Create a` + `\ `
+ environment.config.EXCHANGE_NAME + ' Account & Start Trading!',
    'og:description': `Sign up to ` + environment.config.EXCHANGE_NAME  + ` today! Your digital asset exchange ` + `\
platform for securely trading digital assets against real-time markets! Sign up now & start trading!`,
    'og:image': environment.config.APP_URL + 'assets/' + environment.config.EXCHANGE_NAME_L + '/favicon-96x96.png'
  } }, canActivate: [AuthRerouteGuard, MetaGuard] },
  { path: 'register/:referralcode', component: SignupComponent, data: { title: 'Sign Up',
  meta: {
    title: 'Sign up to ' + environment.config.EXCHANGE_NAME + ' | Create a ' + environment.config.EXCHANGE_NAME + `\
Account & Start Trading!`,
    description: `Sign up to ` + environment.config.EXCHANGE_NAME  + ` today! Your digital asset exchange ` + `\
platform for securely trading digital assets against real-time markets! Sign up now & start trading!`,
    'og:url': environment.config.APP_URL + '/register',
    'og:type': 'website',
    'og:title': 'Sign up to ' + environment.config.EXCHANGE_NAME + ` | Create a` + `\ `
+ environment.config.EXCHANGE_NAME + ' Account & Start Trading!',
    'og:description': `Sign up to ` + environment.config.EXCHANGE_NAME  + ` today! Your digital asset exchange ` + `\
platform for securely trading digital assets against real-time markets! Sign up now & start trading!`,
    'og:image': environment.config.APP_URL + 'assets/' + environment.config.EXCHANGE_NAME_L + '/favicon-96x96.png'
  } }, canActivate: [AuthRerouteGuard, MetaGuard] }
];

export const routing: ModuleWithProviders<AuthModule> = RouterModule.forChild(routes);
